import axios from 'axios';
import { OrchestratorBotDto, PairDto } from '../../types';

class OrchestratorBotApi {
  Get = async (id: string): Promise<OrchestratorBotDto> => {
    return await axios.get(`OrchestratorBot/${id}`).then((response) => response.data);
  };
  GetAll = async (): Promise<OrchestratorBotDto[]> => {
    return await axios.get(`OrchestratorBot/all`).then((response) => response.data);
  };
  GetPairs = async (): Promise<PairDto[]> => {
    return await axios.get(`OrchestratorBot/pairs`).then((response) => response.data);
  };
  SyncPairs = async (): Promise<any> => {
    return await axios.post(`OrchestratorBot/pairs`).then((response) => response.data);
  };
}

export default new OrchestratorBotApi();
