import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { Avatar } from 'antd';
import { UserOutlined, LogoutOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Dropdown, Space } from 'antd';

const ProfileMenu: FC = () => {
  const { user, logout } = useAuth0();
  const navigate = useNavigate();
  const items: MenuProps['items'] = [
    {
      label: <a onClick={() => navigate('/admin/profile')}>Profile</a>,
      key: '0',
      icon: <UserOutlined />,
    },
    {
      label: <a onClick={() => logout()}>Logout</a>,
      key: '1',
      icon: <LogoutOutlined />,
    },
  ];
  return (
    <div style={{ marginLeft: 'auto', marginRight: 20, marginTop: -2, display: 'flex', alignItems: 'center' }}>
      <Dropdown menu={{ items }} trigger={['click']}>
        <div
          style={{
            marginLeft: 'auto',
            marginRight: 20,
            marginTop: -2,
            display: 'flex',
            alignItems: 'center',
          }}>
          <div>{user?.name}</div>
          <Avatar src={user?.picture} style={{ marginLeft: 10, cursor: 'pointer' }}></Avatar>
        </div>
      </Dropdown>
    </div>
  );
};

export default ProfileMenu;
