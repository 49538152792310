export enum BotType {
  OrchestratorBot,
  SignalBot,
  TradingBot,
}

export enum BotStatus {
  Unknown = 0,
  Starting,
  Started,
  Stopping,
  Stopped,
}

export interface OrchestratorBotDto {
  id: string;
  name: string;
  description: string;
  ipAddress: string;
  machineName: string;
  botType: BotType;
  botStatus: BotStatus;
  created: string;
  updated: string;
}

export interface UpdateOrchestratorBotDto {
  id: string;
  name: string;
  description: string;
  botStatus: BotStatus;
}

export interface PairDto {
  symbol: string;
  baseAsset: string;
  quoteAsset: string;
  isActive: boolean;
}
