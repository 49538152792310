import { FC, useEffect, useRef, useState } from 'react';
import OrchestratorBotApi from '../../utils/api/OrchestratorBotApi';
import { BotStatus, OrchestratorBotDto, PairDto } from '../../types';
import { Breadcrumb, Divider, Layout, Space, Table, Tag, Button, Tooltip, message, InputRef, Input } from 'antd';
import type { ColumnType, ColumnsType } from 'antd/es/table';
import { format } from 'date-fns';
import { RightOutlined, SearchOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { selectToken } from '../../store/global';
import { FilterConfirmProps } from 'antd/es/table/interface';
import Highlighter from 'react-highlight-words';

const BreadcrumbContainer = styled.div`
  display: flex;
  align-items: center;
  div {
    margin-left: auto;
  }
`;

type DataIndex = keyof PairDto;

const PairListPage: FC = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const token = useSelector(selectToken);
  const [pairs, setPairs] = useState<PairDto[]>([]);
  const [loading, setLoading] = useState(true);
  const load = () => {
    setLoading(true);
    OrchestratorBotApi.GetPairs()
      .then((a) => {
        setPairs(a);
        setLoading(false);
      })
      .catch((e) => {
        if (e.response.status == 403) {
          messageApi.error('You are not authorized to see this information');
        } else {
          messageApi.error('An error occured');
        }
        setLoading(false);
      });
  };
  const sync = () => {
    setLoading(true);
    OrchestratorBotApi.SyncPairs()
      .then((a) => {
        load();
      })
      .catch((e) => {
        if (e.response.status == 403) {
          messageApi.error('You are not authorized to see this information');
        } else {
          messageApi.error('An error occured');
        }
        setLoading(false);
      });
  };
  useEffect(() => {
    let interval: any;
    if (token) {
      load();
      interval = setInterval(() => {
        load();
      }, 60000);
    }
    return () => clearInterval(interval);
  }, [token]);

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef<InputRef>(null);

  const handleSearch = (selectedKeys: string[], confirm: (param?: FilterConfirmProps) => void, dataIndex: DataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex: DataIndex): ColumnType<PairDto> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button type="primary" onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)} icon={<SearchOutlined />} size="small" style={{ width: 90 }}>
            Search
          </Button>
          <Button onClick={() => clearFilters && handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText((selectedKeys as string[])[0]);
              setSearchedColumn(dataIndex);
            }}>
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}>
            Close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }} searchWords={[searchText]} autoEscape textToHighlight={text ? text.toString() : ''} />
      ) : (
        text
      ),
  });

  const columns: ColumnsType<PairDto> = [
    {
      title: 'Symbol',
      dataIndex: 'symbol',
      key: 'symbol',
      ...getColumnSearchProps('symbol'),
      sorter: (a, b) => b.symbol.localeCompare(a.symbol),
    },
    { title: 'Base Asset', dataIndex: 'baseAsset', key: 'baseAsset' },
    { title: 'Quote Asset', dataIndex: 'quoteAsset', key: 'quoteAsset' },

    {
      title: 'Action',
      dataIndex: 'action',
      key: 'id',
      render: (d) => {
        return (
          <Space wrap>
            {/* <Button type="primary" shape="circle" danger icon={<DeleteOutlined />} size="small" /> */}
            {/* <Button type="primary" shape="circle" icon={<EditOutlined />} size="small" /> */}
            <Tooltip title="View details">
              <Button key={'_detail_' + d} type="default" shape="circle" icon={<RightOutlined />} size="small" />
            </Tooltip>
          </Space>
        );
      },
    },
  ];

  return (
    <>
      {contextHolder}
      <Layout style={{ backgroundColor: 'white' }}>
        <BreadcrumbContainer>
          <Breadcrumb items={[{ title: 'Pairs' }]} />
          <div>
            <Button onClick={sync}>Sync</Button> <Space> </Space> <Button onClick={load}>Refresh</Button>
          </div>
        </BreadcrumbContainer>
        <Divider></Divider>
        <Table rowKey="symbol" loading={loading} columns={columns} dataSource={pairs} pagination={{ defaultPageSize: 20 }} />
      </Layout>
    </>
  );
};
export default PairListPage;

// id: string;
// botType: BotType;
// botStatus: BotStatus;
