import { FC, useEffect } from 'react';
import OrchestratorBotApi from '../utils/api/OrchestratorBotApi';
import { useSelector } from 'react-redux';
import { selectToken } from '../store/global';

const Profile: FC = () => {
  const token = useSelector(selectToken);
  useEffect(() => {
    if (token) {
      OrchestratorBotApi.Get('8307805b-c8cd-4242-99f1-1adfe29df711')
        .then((a) => console.log(a))
        .catch((e) => console.log(e));
      OrchestratorBotApi.Get('all')
        .then((a) => console.log(a))
        .catch((e) => console.log(e));
    }
  }, [token]);

  return <div>Profile</div>;
};
export default Profile;
