import { FC, useEffect, useState } from 'react';
import OrchestratorBotApi from '../../utils/api/OrchestratorBotApi';
import { BotStatus, OrchestratorBotDto } from '../../types';
import { Breadcrumb, Divider, Layout, Space, Table, Tag, Button, Tooltip, message } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { format } from 'date-fns';
import { RightOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { selectToken } from '../../store/global';

const BreadcrumbContainer = styled.div`
  display: flex;
  align-items: center;
  button {
    margin-left: auto;
  }
`;

const OrchestratorBotListPage: FC = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const token = useSelector(selectToken);
  const [bots, setBots] = useState<OrchestratorBotDto[]>([]);
  const [loading, setLoading] = useState(true);
  const load = () => {
    setLoading(true);
    OrchestratorBotApi.GetAll()
      .then((a) => {
        setBots(a);
        setLoading(false);
      })
      .catch((e) => {
        if (e.response.status == 403) {
          messageApi.error('You are not authorized to see this information');
        } else {
          messageApi.error('An error occured');
        }
        setLoading(false);
      });
  };
  useEffect(() => {
    let interval: any;
    if (token) {
      load();
      interval = setInterval(() => {
        load();
      }, 60000);
    }
    return () => clearInterval(interval);
  }, [token]);

  const columns: ColumnsType<OrchestratorBotDto> = [
    { title: 'Name', dataIndex: 'name', key: 'name' },
    { title: 'Description', dataIndex: 'description', key: 'description' },
    { title: 'Machine Name', dataIndex: 'machineName', key: 'machineName' },
    { title: 'Ip Address', dataIndex: 'ipAddress', key: 'ipAddress' },
    { title: 'Created', dataIndex: 'created', key: 'created', render: (d) => format(new Date(d), 'MM/dd/yyyy HH:mm:ss') },
    { title: 'Updated', dataIndex: 'updated', key: 'updated', render: (d) => format(new Date(d), 'MM/dd/yyyy HH:mm:ss') },
    {
      title: 'Bot Status',
      dataIndex: 'botStatus',
      key: 'botStatus',
      render: (s) => {
        if (s == BotStatus.Starting) {
          return <Tag color="green">Starting</Tag>;
        } else if (s == BotStatus.Started) {
          return <Tag color="#87d068">Started</Tag>;
        } else if (s == BotStatus.Stopping) {
          return <Tag color="red">Stopping</Tag>;
        } else if (s == BotStatus.Stopped) {
          return <Tag color="#f50">Stopped</Tag>;
        } else {
          return <Tag color="purple">Unknown</Tag>;
        }
      },
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'id',
      render: (d) => {
        return (
          <Space wrap>
            <Tooltip title="View details">
              <Button key={'_detail_' + d} type="default" shape="circle" icon={<RightOutlined />} size="small" />
            </Tooltip>
          </Space>
        );
      },
    },
  ];
  return (
    <>
      {contextHolder}
      <Layout style={{ backgroundColor: 'white' }}>
        <BreadcrumbContainer>
          <Breadcrumb items={[{ title: 'Orchestrator Bots' }]} />
          <Button onClick={load}>Refresh</Button>
        </BreadcrumbContainer>

        <Divider></Divider>
        <Table rowKey="id" loading={loading} columns={columns} dataSource={bots} />
      </Layout>
    </>
  );
};
export default OrchestratorBotListPage;

// id: string;
// botType: BotType;
// botStatus: BotStatus;
