import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Auth0Provider } from '@auth0/auth0-react';
import { Provider } from 'react-redux';
import store from './store/store';
import { BrowserRouter as Router } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <Auth0Provider
                domain={process.env.REACT_APP_AUTH0_DOMAIN ?? ''}
                clientId={process.env.REACT_APP_AUTH0_CLIENT_ID ?? ''}
                useRefreshTokens={true}
                useRefreshTokensFallback={true}
                authorizationParams={{
                    redirect_uri: window.location.origin,
                    audience: process.env.REACT_APP_AUTH0_AUDIENCE ?? '',
                    scope: 'openid profile email read:current_user update:current_user_metadata  write:stuff delete:stuff read:stuff',
                }}
            >
                <Router>
                    <App />
                </Router>
            </Auth0Provider>
        </Provider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
