import { FC } from 'react';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { Layout, Button, theme } from 'antd';
import { useSelector } from 'react-redux';
import { selectIsMenuCollapsed, setMenuCollapsed } from '../store/global';
import { useAppDispatch } from '../store/store';
import ProfileMenu from './ProfileMenu';

const { Header } = Layout;

const TopMenu: FC = () => {
    const menuCollapsed = useSelector(selectIsMenuCollapsed);
    const dispatch = useAppDispatch();
    const {
        token: { colorBgContainer },
    } = theme.useToken();

    return (
        <Header style={{ padding: 0, background: colorBgContainer, display: 'flex' }}>
            <Button
                type="text"
                icon={menuCollapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                onClick={() => dispatch(setMenuCollapsed(!menuCollapsed))}
                style={{
                    fontSize: '16px',
                    width: 64,
                    height: 64,
                }}
            />
            <ProfileMenu></ProfileMenu>
        </Header>
    );
};

export default TopMenu;
