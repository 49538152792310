import {AnyAction, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "./store";

export type GlobalSliceState = {
  menuCollapsed: boolean;
  token: string;
};

export const globalSlice = createSlice({
  name: 'global',
  initialState: {
    menuCollapsed: false,
    token: ''
  } as GlobalSliceState,
  reducers: {
    resetState: (state, _action: AnyAction) => {
      state.menuCollapsed = false;
      state.token = '';
    },
    setMenuCollapsed: (state, action: PayloadAction<boolean>) => {
      state.menuCollapsed = action.payload;
    },
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    }
  },
});

export const {
  resetState,
  setMenuCollapsed,
  setToken
} = globalSlice.actions;


export const selectIsMenuCollapsed = (state: RootState) => state?.global?.menuCollapsed;
export const selectToken = (state: RootState) => state?.global?.token;

export default globalSlice.reducer;

