import {configureStore, combineReducers, ThunkAction, AnyAction} from '@reduxjs/toolkit';
import {useDispatch} from 'react-redux';
import globalReducer from './global';

const rootReducer = combineReducers({
  global: globalReducer,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});

// Root state type
export type RootState = ReturnType<typeof rootReducer>;
// Thunk type
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, undefined, AnyAction>;
// Export a useDispatch hook that can be reused to resolve types
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = (): AppDispatch => useDispatch<AppDispatch>();

export default store;
