import { FC } from 'react';
import { AlertOutlined, ClusterOutlined, DollarOutlined, SettingOutlined } from '@ant-design/icons';
import { Layout, Menu } from 'antd';
import { useSelector } from 'react-redux';
import { selectIsMenuCollapsed } from '../store/global';
import { useNavigate } from 'react-router-dom';

const { Sider } = Layout;

const LeftMenu: FC = () => {
  const menuCollapsed = useSelector(selectIsMenuCollapsed);
  const navigate = useNavigate();
  return (
    <Sider trigger={null} collapsible collapsed={menuCollapsed}>
      <div className="demo-logo-vertical">Hello</div>
      <Menu
        theme="dark"
        mode="inline"
        defaultSelectedKeys={['1']}
        items={[
          {
            key: '1',
            icon: <ClusterOutlined />,
            label: 'Orchestrator Bots',
            onClick: () => {
              navigate('/admin/bots/orchestrator');
            },
          },
          {
            key: '2',
            icon: <AlertOutlined />,
            label: 'Signal Bots',
          },
          {
            key: '3',
            icon: <DollarOutlined />,
            label: 'Pairs',
            onClick: () => {
              navigate('/admin/pairs');
            },
          },
          {
            key: '4',
            icon: <SettingOutlined />,
            label: 'Settings',
          },
        ]}
      />
    </Sider>
  );
};

export default LeftMenu;
