import { useEffect, type FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

import { Layout, Spin, theme, message } from 'antd';
import Detail from './pages/Detail';
import TopMenu from './components/TopMenu';
import LeftMenu from './components/LeftMenu';
import { LoadingOutlined } from '@ant-design/icons';

import 'antd/dist/reset.css';
import './App.css';
import './index.css';
import Profile from './pages/Profile';
import { useAppDispatch } from './store/store';
import { selectToken, setToken } from './store/global';
import axios from 'axios';
import OrchestratorBotListPage from './pages/OrchestratorBot/List';
import { useSelector } from 'react-redux';
import Dashboard from './pages/Dashboard';
import PairListPage from './pages/Pair/List';

const { Content } = Layout;

const App: FC = () => {
  const token = useSelector(selectToken);
  const dispatch = useAppDispatch();
  const { loginWithRedirect, isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
  const antIcon = <LoadingOutlined style={{ fontSize: 64 }} spin />;
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  axios.defaults.headers.common['Content-Type'] = 'application/json';
  axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    if (isAuthenticated) {
      const getAccessToken = async () => {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}`,
            scope: 'read:stuff write:stuff delete:stuff',
          },
        });
        dispatch(setToken(accessToken));
        axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
      };
      getAccessToken();
    }
  }, [isAuthenticated]);

  if (isLoading) {
    return (
      <Layout style={{ height: '100vh', background: colorBgContainer, justifyContent: 'center' }}>
        <Spin indicator={antIcon} className="spinner" />
      </Layout>
    );
  }
  if (!isAuthenticated) {
    loginWithRedirect();
  }
  return (
    <div className="App">
      <Layout style={{ minHeight: '100vh' }}>
        <LeftMenu />
        <Layout>
          <TopMenu />
          <Content
            style={{
              margin: '24px 16px',
              padding: 24,
              minHeight: 280,
              background: colorBgContainer,
            }}>
            <Routes>
              {/* <Route path="admin" element={<RouteGuard guard={() => !!token}></RouteGuard>}> */}
              <Route path="/" element={<Dashboard />} />
              <Route path="admin">
                <Route path="profile" element={<Profile />} />
                <Route path="bots/orchestrator" element={<OrchestratorBotListPage />} />
                <Route path="detail" element={<Detail />} />
                <Route path="pairs" element={<PairListPage />} />
              </Route>
            </Routes>
          </Content>
        </Layout>
      </Layout>
    </div>
  );
};

export default App;
